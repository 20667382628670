/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.mainCardContent{
  // center align the text
  text-align: center;
  // set the font size
  font-size: 20px;
  // set the font weight
  font-weight: bold;
}

.card-center{
  width: 70%;
  display: block;
  margin: 0 auto;
}

.card-shadow{
  border-radius: 10px;
  box-shadow: 5px 5px 10px #2d2d2d;
}

.small-logo{
  width: 50px;
  height: 50px;
}

// Adding animations
// An animation to fade in
@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}
// An animation to slide in from the left
@keyframes slide-in-left {
  from {transform: translateX(-100%);}
  to {transform: translateX(0);}
}
// An animation to fade in and slide in from the bottom
@keyframes fade-in-slide-in-bottom {
  from {opacity: 0; transform: translateY(100%);}
  to {opacity: 1; transform: translateY(0);}
}

ion-card {
  animation: fade-in-slide-in-bottom 1s;
}
